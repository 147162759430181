<template>
  <div>
    <div v-if="!isMobile">
      <div class="aboutones">
        <div v-for="(item, index) in videoList" :key="index">
          <videoindex
            :id="'ads' + index"
            :item="item"
            :ref="'deo' + index"
            :isones="index == 0 ? true : false"
          ></videoindex>
        </div>
        <div class="titlechange"></div>
        <div
          class="sones color-white letter-5"
          :class="{ 'animate__bounceInLeft animate__animated': pop }"
        >
          <div class="fs60 fw600">{{ titlist[active].tit }}</div>
          <div class="fs34 pt40 fw600">{{ titlist[active].desc }}</div>
        </div>
      </div>

      <div class="imgbox">
        <img :src="p2" alt="" />
      </div>
      <!-- 人才招聘 -->
      <div class="bg-black pb50">
        <div class="box1200auto">
          <div class="pt30 fs30 color-white pb50">人才招聘</div>
          <div v-for="(item, index) in peopList" :key="index">
            <collitems
              :title="
                item.title + ' ( ' + item.address + ' / ' + item.num + '人 )'
              "
              :croped="false"
            >
              <div class="flex color-white">
                <!-- 招聘职位 -->
                <div
                  class="width-50 pr50"
                  v-for="(amt, dex) in item.job"
                  :key="dex"
                >
                  <!-- 岗位职责，任职要求 -->
                  <div class="pt30 fs22 color-white pb50">
                    {{ amt.tit + " : " }}
                  </div>

                  <!-- 要求条款  -->
                  <div
                    class="pt5 pb20 lh-30"
                    v-for="(a, b) in amt.list"
                    :key="b"
                  >
                    {{ a.content }}
                  </div>
                </div>
              </div>
            </collitems>
          </div>

          <div class="color-white">
            <div class="fs25 pt50">{{ meslis.tels }}</div>
            <div class="fs25 pt10">{{ meslis.yx }}</div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isMobile">
      <div class="aboutones">
        <div v-for="(item, index) in videoList" :key="index">
          <mobilenewtest
            :id="'ads' + index"
            :item="item"
            :ref="'deo' + index"
            :isones="index == 0 ? true : false"
          ></mobilenewtest>
        </div>
        <div class="titlechange"></div>
        <div
          class="sones1 width-full color-white letter-5 pl15 pr15"
          :class="{ 'animate__bounceInLeft animate__animated': pop }"
        >
          <div class="fs32 text-center fw600">{{ titlist[active].tit }}</div>
          <div class="fs20 text-center pt40 fw600">
            {{ titlist[active].desc }}
          </div>
        </div>
      </div>

      <div class="imgbox relative">
        <div class="absolute top left pt30 fs30 color-white pl15 pt30">
          发展历程
        </div>
        <img :src="p2" alt="" />
      </div>
      <!-- 人才招聘 -->
      <div class="bg-black pb50">
        <div class="pl15 pr15">
          <div class="pt30 fs30 color-white pb50">人才招聘</div>
          <div v-for="(item, index) in peopList" :key="index">
            <mobilecollitems
              :title="
                item.title + ' ( ' + item.address + ' / ' + item.num + '人 )'
              "
              :croped="false"
            >
              <div class="color-white">
                <!-- 招聘职位 -->
                <div class="" v-for="(amt, dex) in item.job" :key="dex">
                  <!-- 岗位职责，任职要求 -->
                  <div class="pt10 fs22 color-white pb10">
                    {{ amt.tit + " : " }}
                  </div>

                  <!-- 要求条款  -->
                  <div
                    class="pt5 pb5 lh-30"
                    v-for="(a, b) in amt.list"
                    :key="b"
                  >
                    {{ a.content }}
                  </div>
                </div>
              </div>
            </mobilecollitems>
          </div>

          <div class="color-white">
            <div class="fs20 pt50">{{ meslis.tels }}</div>
            <div class="fs20 pt10">{{ meslis.yx }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import hendtitle from "@/components/pc/hendtitle";
// import jjfnhend from "@/assets/images/jjfnhend.jpg";
// import mobilehendtitle from "@/components/pc/mobilehendtitle";
import videoindex from "@/components/pc/videoindex";
import collitems from "@/components/pc/toopen/collitems";
// import video1 from "../../assets/images/detileimg/aboutus.mp4";
import p1 from "@/assets/images/about/p1.jpg";
import p2 from "@/assets/images/about/p2.jpg";
import p3 from "@/assets/images/about/p3.jpg";

// 手机
import mobilenewtest from "@/components/mobile/mobilenewtest";
import mp1 from "@/assets/images/about/mp1.jpg";
import mp2 from "@/assets/images/about/mp2.jpg";
import mobilecollitems from "@/components/mobile/toopen/collitems";

import { look_mixin } from "@/utils/looktime.js";
export default {
  components: {
    // hendtitle,
    // mobilehendtitle,
    mobilecollitems,
    mobilenewtest,
    videoindex,
    collitems,
  },
  mixins: [look_mixin],
  computed: {
    ...mapGetters(["isMobile"]),
  },
  data() {
    return {
      meslis: {
        tels: "招聘电话 : 021-3750 9071",
        yx: "邮箱 : hr@zoesolar.com",
      },
      videoList: [
        {
          id: 1,
          title: "",
          desc: "",
          src: p1,
          isvideo: false,
          pages: false,
          runurl: "/about",
          img: "img1",
        },
      ],
      p2: p2,
      p3: p3,
      active: 0,
      imtvals: null,
      titlist: [
        { id: 0, tit: "愿 景", desc: "创新绿色能源 成就智慧生活" },
        { id: 0, tit: "使 命", desc: "成为改变能源结构的全球化企业" },
        { id: 0, tit: "价 值 观", desc: "正直 创新 协同 共享" },
      ],
      pop: false,
      peopList: [
        {
          id: 1,
          title: "产品总监",
          address: "产品部 上海-青浦",
          num: 1,
          job: [
            {
              tit: "岗位职责",
              list: [
                {
                  content:
                    "1. 负责储能业务技术规划与落地：以行业发展趋势、性价比为维度，组织规划开发有特色有竞争优势的解决方案与产品；并组织深度研究商业模式为解决方案产品市场化铺平道路；",
                },
                {
                  content:
                    "2. 产品规划：带领储能研发团队对负责的产品包括储能一体柜、储能分体规、长时储能产品、PCS等产品进行规划管理，对产品竞争力与风险进行管控；",
                },
                {
                  content:
                    "3. 产品全生命周期管理：负责产品立项至进入市场后的全周期管理，对不同阶段的产品分类管理，持续推动进行产品的优化和迭代，确保产品具有相对竞争优势；",
                },
                {
                  content:
                    "4. 负责管理公司产品的认证和标准化工作，确保产品符合政府和市场的法规和标准；",
                },
                {
                  content:
                    "5. 负责根据客户需求进行储能系统的解决方案设计审核；",
                },
                {
                  content:
                    "6. 负责储能系统的运行测试和数据分析和仿真，提升储能系统运行效率；",
                },
                {
                  content:
                    "7. 负责解决储能系统在集成方面的关键技术难点，及时解决储能系统在运行当中存在的各种问题。",
                },
              ],
            },
            {
              tit: "任职资格",
              list: [
                { content: "1. 本科及以上学历，电力电子等相关专业；" },
                {
                  content: "2. 具有10年以上储能系统产品开发的相关经验与经历；",
                },
                {
                  content: "3. 熟悉锂电池/钠电池特性，精通储能电池系统的设计；",
                },
                {
                  content:
                    "4. 熟悉电力电子/模电/数电等相关电路，对嵌入式软件编程有所了解；",
                },
                { content: "5. 熟悉储能电池/电气等相关的标准及规范。" },
              ],
            },
          ],
        },
        {
          id: 4,
          title: "解决方案工程师",
          address: "产品部 上海-青浦",
          num: 1,
          job: [
            {
              tit: "岗位职责",
              list: [
                {
                  content:
                    "1. 负责大储、工商储项目需求编制切合实际的解决方案；",
                },
                {
                  content:
                    "2. 对储能项目开发阶段的电力接入、选址等相关问题，提供必要的技术支持；",
                },
                { content: "3. 负责相关标书制作，负责技术标投递工作；" },
                {
                  content:
                    "4. 负责产品解决方案宣讲、技术答疑等技术支撑性工作；",
                },
                {
                  content:
                    "5. 分析比较市场主流储能系统解决方案的技术优劣势，结合公司现有产品及时更新公司标准解决方案；",
                },
                {
                  content:
                    "6. 跟踪储能最新政策，不断探索学习不同的项目商业模式。",
                },
              ],
            },
            {
              tit: "任职资格",
              list: [
                { content: "1. 本科及以上学历，电气自动化等相关专业；" },
                {
                  content:
                    "2. 3年以上新能源或储能系统解决方案设计经验，1年以上解决方案经验；",
                },
                {
                  content:
                    "3. 责任心强，工作主动，具有较强的学习能力和洞察力。",
                },
              ],
            },
          ],
        },
        {
          id: 2,
          title: "高级品牌经理",
          address: "市场部 上海-青浦",
          num: 1,
          job: [
            {
              tit: "岗位职责",
              list: [
                {
                  content:
                    "1. 制定全球品牌战略，明确品牌愿景、目标和定位，构建完整的品牌体系；",
                },
                {
                  content:
                    "2. 根据公司业务发展和市场趋势，制定年度品牌推广计划和预算，确保品牌建设与公司整体战略相一致；",
                },
                {
                  content:
                    "3. 策划并执行全球品牌推广活动，包括但不限于线上广告、社交媒体营销、行业展会、线下活动等，提升品牌知名度和影响力；",
                },
                {
                  content:
                    "4. 负责品牌宣传物料的设计、制作和管理，包括品牌手册、宣传册、海报、视频等，确保品牌传播的一致性和专业性；",
                },
                {
                  content:
                    "5. 组织和参与国际行业展会、研讨会等活动，展示公司品牌形象和产品优势，拓展国际客户资源；",
                },
                {
                  content:
                    "6. 跟踪品牌推广效果，通过数据分析评估活动效果，优化品牌推广策略，提高品牌投入产出比。",
                },
              ],
            },
            {
              tit: "任职资格",
              list: [
                {
                  content:
                    "1. 本科及以上学历，市场营销、品牌管理、传媒等相关专业优先；",
                },
                {
                  content:
                    "2. 英语流利，具备英语专业八级或同等水平证书，能够以英语作为工作语言进行专业书写和表达；",
                },
                {
                  content:
                    "3. 5年以上品牌管理经验，其中至少2年在国际或跨国企业从事品牌推广工作，有能源、储能或相关行业经验者优先；",
                },
                {
                  content:
                    "4. 熟悉品牌管理理论和实践，具备丰富的品牌战略规划、品牌推广和市场调研经验；",
                },
                {
                  content:
                    "5. 精通品牌推广工具和渠道，包括社交媒体、数字营销、公关活动等，能够灵活运用多种手段提升品牌影响力；",
                },
                {
                  content:
                    "6. 具备较强的数据分析能力，能够通过数据驱动品牌决策；",
                },
                {
                  content:
                    "7. 具有较强的文字撰写能力，能够独立撰写品牌推广文案、新闻稿、品牌故事、行业报告等，文字表达准确、流畅、富有感染力；",
                },
                {
                  content:
                    "8. 具备出色的跨部门协作能力，能够与公司内部各部门紧密配合，推动品牌项目落地。",
                },
              ],
            },
          ],
        },
        {
          id: 3,
          title: "海外销售经理",
          address: "销售部 上海-青浦",
          num: "若干",
          job: [
            {
              tit: "岗位职责",
              list: [
                {
                  content:
                    "1. 负责公司储能产品的海外销售及推广，完成制定的销售目标；",
                },
                {
                  content:
                    "2. 熟悉海外储能业务的拓展、流程的运作，能独立开发新客户；",
                },
                {
                  content:
                    "3. 负责完成调查、分析同类产品的市场行情，收集和分析产品技术资料和销售数据；",
                },
                {
                  content:
                    "4. 负责建立客户的资料及档案，与客户进行沟通、联络，维护客户关系；",
                },
                {
                  content:
                    "5. 负责支持客户审厂对接，生产质量监控，货柜监装及售后跟进处理；",
                },
                {
                  content:
                    "6. 负责按需配合参与海外展会及完成领导交办的其他任务。",
                },
              ],
            },
            {
              tit: "任职资格",
              list: [
                { content: "1. 本科及以上学历，市场营销等相关专业优先；" },
                {
                  content:
                    "2. 掌握至少一门可以作为工作语言的外语，英语、韩语、德语、西班牙语、葡萄牙语、法语、意大利语以及其他小语种；",
                },
                {
                  content:
                    "3. 具有3年以上海外销售经验，其中1年新能源行业销售经验，熟悉储能产品等销售优先；",
                },
                {
                  content:
                    "4. 工作积极，乐于较为频繁的出差，能承受一定的工作压力，有强烈的责任心和团队合作精神。",
                },
              ],
            },
          ],
        },

        {
          id: 5,
          title: "海外服务工程师",
          address: "全球服务部 上海-青浦",
          num: 1,
          job: [
            {
              tit: "岗位职责",
              list: [
                {
                  content:
                    "1. 对接驻外售后服务工程师、三方服务公司，及时有效的解决前方诉求；",
                },
                {
                  content: "2. 整理负责的区域内的售后客诉台账，定期汇报上级；",
                },
                {
                  content:
                    "3. 不定期出差海外，对驻外人员、三方服务公司进行培训赋能，提升海外团队业务能力；",
                },
                { content: "4. 上级安排的其他相关工作。" },
              ],
            },
            {
              tit: "任职资格",
              list: [
                { content: "1. 电力电子专业/电化学专业，本科及以上学历；" },
                { content: "2. 1年以上售后服务工作经验；" },
                { content: "3. CET四级，口语流利。" },
              ],
            },
          ],
        },
        {
          id: 6,
          title: "结构工程师",
          address: "研发部 江苏-常州",
          num: 1,
          job: [
            {
              tit: "岗位职责",
              list: [
                {
                  content:
                    "1. 负责锂电池pack设计、储能系统产品的结构设计，包括机械设计、部件选型、设计图纸输出；",
                },
                {
                  content:
                    "2. 负责储能系统电池控制柜、配电柜、系统接入柜等电气成套柜产品的结构设计，包括元器件布局、布线和设计图纸输出；",
                },
                { content: "3. 负责储能的散热仿真、设计；" },
                {
                  content:
                    "4. 解决产品生产装配过程中的技术问题，参与产品的试制，调试，转产工作；",
                },
                {
                  content:
                    "5. 对产品持续优化，通过改进设计方案，改进材料，增加零件的通用性控制成本。",
                },
              ],
            },
            {
              tit: "任职资格",
              list: [
                {
                  content:
                    "1. 本科及以上学历，机械类相关专业优先，3年及以上结构设计经验，熟知产品结构开发设计流程，独立完成设计；",
                },
                {
                  content:
                    "2. 3年及以上产品钣金设计经验，熟悉激光，数冲，折弯，焊接工艺，精通储能产品PACK结构设计；",
                },
                {
                  content:
                    "3. 熟练应用Pro/E，Auto CAD等设计软件，有电池pack结构设计、储能集装箱结构设计经验者优先；",
                },
                { content: "4. 工作态度严谨，有良好团队协作能力。" },
              ],
            },
          ],
        },
        {
          id: 12,
          title: "区域开发经理",
          address: "开发部 江苏",
          num: "若干",
          job: [
            {
              tit: "岗位职责",
              list: [
                {
                  content:
                    "1. 负责光伏，风能发电站项目前期开发，进行项目开发前期选址、布局、投资环境考察，以及政府初期沟通等工作、项目申报直至取得备案或核准；",
                },
                {
                  content:
                    "2. 负责统筹协调内外资源，确保项目的预可研、可研申报、项目核准、并网接入等工作。负责统筹协调资源，进行项目的选址、土地租用、项目申报的前期开发工作；取得项目所需的国土预审、地质灾害、水土保持、环评、文物、电力接入方案批复等电站建设、并网接入所需要的各项核准和许可；",
                },
                {
                  content:
                    "3. 建立电站合作开发伙伴模式，采取合作、合资等形式推进电站项目的开发、建设、销售。在专业技术人员的协助下，完成电站投资分析报告；",
                },
                {
                  content:
                    "4. 考察、评估电站项目开发可行性，组织并参与项目商务谈判，协调各相关部门和机构，签订商务合作协议；洽谈并签订商务合作协议；",
                },
                {
                  content:
                    "5. 建立维护区域政府、电力公司、开发商、设计院等网络关系；",
                },
                {
                  content:
                    "6. 熟悉了解国家有关太阳能光伏，风能电站的各项政策及地方的政策；充分了解所在区域的太阳能光伏、风能电站的发展规划，市场竞争情况；提供有价值的项目开发计划。",
                },
              ],
            },
            {
              tit: "任职资格",
              list: [
                {
                  content:
                    "1. 大专学历及以上，光伏或新能源行业工程技术或商务背景；",
                },
                {
                  content:
                    "2. 熟悉电站项目前期开发、项目管理、项目投标和开发等相关知识；",
                },
                {
                  content:
                    "3. 有复合型夸领域项目开发、投资、政府公关等从业经验者优先考虑；",
                },
                { content: "4. 可适应按项目需要的不定期出差；" },
                {
                  content:
                    "5. 具备良好的沟通与协调能力，计划与组织能力，愿意接受挑战。",
                },
              ],
            },
          ],
        },
        {
          id: 7,
          title: "开发经理",
          address: "开发部 江苏-苏州",
          num: "若干",
          job: [
            {
              tit: "岗位职责",
              list: [
                {
                  content:
                    "1. 负责区域内储能产品销售、项目开发及相关市场发展动向及行业从业者竞争力分析评估并制定有效的营销策略；",
                },
                { content: "2. 负责营销团队的能力建设及有序管理；" },
                { content: "3. 负责年度营销目标和价值传播目标的规划及实现；" },
                {
                  content:
                    "4. 以市场为导向，积极推动内部信息透明无碍沟通，市场需求及时反馈至生产、技术部门并获得相应有效的支持，为客户提供有竞争力的解决方案。",
                },
              ],
            },
            {
              tit: "任职资格",
              list: [
                {
                  content: "1. 本科及以上学历，市场营销、国际贸易等相关专业；",
                },
                {
                  content:
                    "2. 3年以上销售管理工作经验，其中1年以上以上新能源行业销售经验，有储能系统集成产品、电池、组件、逆变器、支架、箱变、风机等销售工作经验优先考虑；",
                },
                {
                  content:
                    "3. 熟悉储能、新能源行业发展情况，熟悉国内外能源客户业务运作模式，工商业分布式光储项目端有较好的客户资源积累；",
                },
                {
                  content:
                    "4. 工作积极，乐于较为频繁的出差，能承受一定的工作压力。",
                },
              ],
            },
          ],
        },
        {
          id: 8,
          title: "电力交易经理",
          address: "运营部 上海/贵州",
          num: "若干",
          job: [
            {
              tit: "岗位职责",
              list: [
                {
                  content:
                    "1. 负责区域内虚拟电厂的电能交易管理，进行基本的电价预测，制订用户购电策略及计划、操作交易电能，降低用电成本;",
                },
                {
                  content:
                    "2. 研究市场动态，分析竞争对手的情况，为公司制定销售策略提供数据支持;",
                },
                {
                  content:
                    "3. 客户开发与维护：通过各种渠道寻找潜在客户，了解客户需求，提供合适的电力产品和服务；建立和维护良好的客户关系，定期跟进客户反馈，解决客户问题，提高客户满意度；收集和整理客户信息，建立客户档案，确保客户资料的完整性和准确性;",
                },
                {
                  content:
                    "4. 研究重点区域的交易品种(现货、中长期)、需求响应、调峰、调频、绿电、绿证、碳资产、虚拟电厂的测算及结算模型，推动虚拟电厂实践参与；",
                },
                {
                  content:
                    "5. 全国电力市场相关政策、交易规则、市场数据的汇总和分析，持续反馈政策与市场动向。",
                },
              ],
            },
            {
              tit: "任职资格",
              list: [
                { content: "1. 本科及以上学历，电力、电气、能源相关专业;" },
                {
                  content:
                    "2. 有电力行业背景，具有3年以上行业经验，熟悉电力交易流程优先;",
                },
                {
                  content:
                    "3. 熟悉电力系统运行，了解调度、交易、配电、需求侧管理等相关知识，对电力市场相关政策、交易规则、市场发展情况有深入的了解；",
                },
                {
                  content:
                    "4. 具备优秀的数据分析能力和学习能力，熟悉优化算法、统计分析方法，掌握常用的数据挖掘工具；",
                },
                {
                  content:
                    "5. 良好的沟通表达能力，优秀的需求理解、逻辑分析能力。",
                },
              ],
            },
          ],
        },
        {
          id: 9,
          title: "客户经理（投后运营）",
          address: "运营部 上海/浙江",
          num: 1,
          job: [
            {
              tit: "岗位职责",
              list: [
                {
                  content:
                    "1. 负责维护客户，参与储能项目并网验收后的客户交底工作，协助客户经理主管完成现场数据核对及电费收益说明；负责项目交接阶段与客户的现场沟通，确保客户对项目情况、收益计算及后续服务有清晰了解；",
                },
                {
                  content:
                    "2. 全周期跟进客户关系维护，包括日常沟通、疑难问题解答、故障协调以及节假日关怀；定期走访客户，保持客户粘性，提升客户满意度；",
                },
                {
                  content:
                    "3. 协助运维及财务部门完成每月电费数据的抄表、对账及收益核算；与客户电工、财务等相关人员沟通，确保回款及时；",
                },
                {
                  content:
                    "4. 在客户出现欠费或提出异议时，及时分析问题原因，开展商务沟通与协商；协助制定催收方案，确保公司合法权益；",
                },
                {
                  content:
                    "5. 与工程、运维、财务及法务等部门紧密协作，保障项目各环节的高效衔接；参与推动部分标准化服务流程及数据管理平台的初步建设，积累产品优化经验。",
                },
              ],
            },
            {
              tit: "任职资格",
              list: [
                {
                  content:
                    "1. 本科及以上学历，能源管理、工程管理、经济、财务、法学或相关专业优先；",
                },
                {
                  content:
                    "2. 2年以上B端客户关系管理、项目管理或能源服务相关工作经验；",
                },
                {
                  content:
                    "3. 熟悉储能商业模式，能够解读电费账单、计算储能收益并清晰讲解计算逻辑；",
                },
                {
                  content:
                    "4. 出色的沟通能力，具备独立处理客户疑难问题和纠纷的能力，必要时能与主管协同开展商务谈判；",
                },
                {
                  content:
                    "5. 服从并配合客户经理主管的工作安排，具备良好的团队意识和执行力；",
                },
                { content: "6. 有抗压与适应能力较强，能够适应省内频繁出差。" },
              ],
            },
          ],
        },
        {
          id: 10,
          title: "高级软件工程师",
          address: "数字能源部 上海-青浦",
          num: 1,
          job: [
            {
              tit: "岗位职责",
              list: [
                {
                  content:
                    "1. 负责储能EMS系统、智能网关的系统架构设计、需求分析、设计开发、测试；",
                },
                {
                  content:
                    "2. 负责行业先进技术的跟踪研究及EMS核心技术攻关，不同具体场景的控制策略及算法研究及实现；",
                },
                { content: "3. 负责EMS在实际项目中的功能验证和设计持续优化；" },
                {
                  content:
                    "4. 负责EMS、智能网关和EMS云平台及虚拟电厂系统的上下行通讯及协同控制功能实现。",
                },
              ],
            },
            {
              tit: "任职资格",
              list: [
                {
                  content:
                    "1. 本科及以上学历，电力系统、计算机软件等相关专业，3年以上工作经验；",
                },
                { content: "2. 熟悉Linux操作系统，熟悉c/c++、QT等界面编程；" },
                {
                  content:
                    "3. 熟悉MODBUS、IEC104、645、CAN、61850等常用电力通讯规约，有相关通讯模块的开发、测试及项目交付经验；",
                },
                {
                  content:
                    "4. 熟悉工商业储能系统业务需求，有储能或变电站监控系统软件架构设计经验，应用开发经验；",
                },
                { content: "5. 具有良好的沟通能力和团队合作精神。" },
              ],
            },
          ],
        },
        {
          id: 11,
          title: "海外法务专员",
          address: "风控部 上海-青浦",
          num: 1,
          job: [
            {
              tit: "岗位职责",
              list: [
                { content: "1. 法规、行业政策检索及解读（包括海外）；" },
                {
                  content:
                    "2. 协助处理公司内部各部门（包括人事、IT、研发、市场等）法律咨询；",
                },
                { content: "3. 协助草拟合同模板、审核相关合同（包括涉外）；" },
                {
                  content:
                    "4. 协助处理诉讼案件（包括但不限于证据收集、立案材料准备、法律问题研究）及诉讼案卷归档；",
                },
                { content: "5. 部门安排的其他工作。" },
              ],
            },
            {
              tit: "任职资格",
              list: [
                {
                  content:
                    "1. 本科以上学历，法学专业，通过国家司法考试（A证），LLM优先；",
                },
                {
                  content:
                    "2. 1-2年涉外工作实操经验（包括但不限于境外上市、投融资、外汇管制、税务筹划等），有律所工作经历优先；",
                },
                {
                  content:
                    "3. 通过CET-6，具有良好的英文听说读写能力，能够适应全英工作环境，能够拟定英文合同；",
                },
                {
                  content:
                    "4. 具有清晰的法律逻辑思维能力，责任心强，具有良好的沟通、协调能力，较强的文字表达能力。",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      this.$refs.deo0[0].videoplay();
      // this.videoplayer = this.$refs.video1;
      // this.videoplayer2 = this.$refs.video2;
      // this.videoplayer3 = this.$refs.video3;
    }, 500);
    this.imtvals = setInterval(() => {
      this.changes();
    }, 5000);

    if (this.isMobile) {
      this.p2 = mp2;
      this.videoList[0].src = mp1;
      this.p1 = mp1;
    }
  },
  beforeDestroy() {
    clearInterval(this.imtvals);
  },
  methods: {
    changes() {
      if (this.active >= 2) {
        this.active = 0;
      } else {
        this.active = this.active + 1;
      }
      this.pop = true;
      setTimeout(() => {
        this.pop = false;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.aboutones {
  position: relative;
  .titlechange {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.3);
  }
  .sones {
    position: absolute;
    top: 50%;
    left: 10%;
  }
  .sones1 {
    position: absolute;
    top: 40%;
    left: 0;
  }
}

.imgbox {
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
</style>